import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }
const _hoisted_2 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_3 = { class: "flex flex-row justify-between items-center pt-8 pb-4" }
const _hoisted_4 = { class: "flex flex-row flex-no-wrap items-center relative" }
const _hoisted_5 = { class: "space-y-3 px-2 pb-4" }
const _hoisted_6 = { class: "pl-2.5 py-2 space-y-2" }
const _hoisted_7 = { class: "overflow-auto h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Search = _resolveComponent("Search")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_custom_dropdown = _resolveComponent("custom-dropdown")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_WidgetDownload = _resolveComponent("WidgetDownload")!
  const _component_ModalDownload = _resolveComponent("ModalDownload")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_LpButton, {
            customClass: " py-2",
            textColor: "white",
            iconLeft: "document-download-outline-white",
            title: "Download Data",
            onClick: _cache[1] || (_cache[1] = () => _ctx.openDownload(true))
          })
        ]),
        _: 1
      }),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: () => _ctx.onSearch(''),
            value: _ctx.filter.search,
            placeholder: "Cari Tiket / No. STT"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode(_component_time_range_picker, {
            startDate: _ctx.filter.startDate,
            endDate: _ctx.filter.endDate,
            onSelect: _ctx.setDateRange,
            default: "hari ini",
            class: "mr-4",
            isCloseDateAfterSelect: "",
            ref: "dateRangeFilterListTicketDex"
          }, null, 8, ["startDate", "endDate", "onSelect"]),
          _createVNode(_component_AdvancedFilter, {
            "custom-max-height": "60vh",
            "model-value": _ctx.filter,
            isScroll: false,
            isDisabled: false,
            onOpened: _ctx.onOpenedAdvancedFilter,
            onReset: _ctx.onResetAdvancedFilter,
            addSumFilter: _ctx.sumFilter
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_5, [
                _createVNode(_component_DataWrapper, { label: "Alasan DEX" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_custom_dropdown, {
                      placeholder: "Pilih Alasan DEX",
                      value: _ctx.totalReasonValue,
                      class: "mt-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode("div", _hoisted_6, [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.reasonData, (reason, index) => {
                            return (_openBlock(), _createBlock("div", {
                              key: index,
                              class: "p-field-checkbox mt-1 flex flex-row"
                            }, [
                              _createVNode(_component_Checkbox, {
                                name: "reason-dex",
                                id: index,
                                value: reason.value,
                                modelValue: _ctx.filter.reason,
                                "onUpdate:modelValue": ($event: any) => (_ctx.filter.reason = $event),
                                onChange: _ctx.onChangeAdvancedFilter
                              }, null, 8, ["id", "value", "modelValue", "onUpdate:modelValue", "onChange"]),
                              _createVNode("label", {
                                for: String(index),
                                class: "ml-2.5"
                              }, _toDisplayString(reason.value) + " - " + _toDisplayString(reason.name), 9, ["for"])
                            ]))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DataWrapper, { label: "Status DEX Asesmen" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomDropdown, {
                      selectedItem: _ctx.filter.statusDexAssesment,
                      options: _ctx.statusDexAssesmentData,
                      placeholder: "Pilih Status DEX",
                      onSelect: _ctx.selectStatusDexAssesment,
                      class: "mt-2"
                    }, null, 8, ["selectedItem", "options", "onSelect"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DataWrapper, { label: "Status Sanggah Konsolidator" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomDropdown, {
                      selectedItem: _ctx.filter.statusDisputeConsole,
                      options: _ctx.statusDisputeConsoleData,
                      placeholder: "Pilih Status Sanggah",
                      onSelect: _ctx.selectStatusDisputeConsole,
                      class: "mt-2"
                    }, null, 8, ["selectedItem", "options", "onSelect"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DataWrapper, { label: "Cari Nama CS" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SelectSearch, {
                      onFocus: _cache[2] || (_cache[2] = e => _ctx.fetchCustomerService(e, 'focus')),
                      onFilter: _ctx.fetchCustomerService,
                      isLoading: _ctx.isLoadingCustomerService,
                      options: _ctx.customerServicesData,
                      keyName: "name",
                      keyValue: "value",
                      placeholder: "Ketik atau pilih nama cs",
                      modelValue: _ctx.filter.customerService,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filter.customerService = $event)),
                      onChange: _ctx.onChangeCustomerService,
                      class: "mt-2"
                    }, null, 8, ["onFilter", "isLoading", "options", "modelValue", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DataWrapper, { label: "Konsolidator" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SelectSearch, {
                      onFocus: _cache[4] || (_cache[4] = e => _ctx.fetchConsole(e, 'focus')),
                      onFilter: _ctx.fetchConsole,
                      isLoading: _ctx.isLoadingConsole,
                      options: _ctx.consoleData,
                      keyName: "name",
                      keyValue: "value",
                      placeholder: "Ketik atau pilih 3LC",
                      modelValue: _ctx.filter.console,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.filter.console = $event)),
                      onChange: _ctx.onChangeConsole,
                      class: "mt-2"
                    }, null, 8, ["onFilter", "isLoading", "options", "modelValue", "onChange"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["model-value", "onOpened", "onReset", "addSumFilter"])
        ])
      ]),
      _withDirectives(_createVNode("div", {
        class: "fixed right-0 h-full z-20",
        style: 
        `background-color:rgba(0,0,0,0.3); top: 15rem; width: calc(100% - ${
          _ctx.isAdvancedFilterOpened ? `60px` : `264px`
        });`
      
      }, null, 4), [
        [_vShow, _ctx.isAdvancedFilterOpened]
      ]),
      _createVNode("div", _hoisted_7, [
        _createVNode(_component_TableV2, {
          loading: _ctx.listData.loading,
          borderTop: false,
          borderBottom: false,
          columns: _ctx.columns,
          data: _ctx.listData.data,
          isEmpty: _ctx.isEmpty,
          emptyHeaderMessage: "Tidak Ada Daftar Tiket DEX",
          emptyMessage: "Mulai buat Daftar Tiket DEX dan cek daftarnya disini",
          class: "my-2",
          onClick: _ctx.onClickRow,
          isDetailAble: "",
          pagination: _ctx.listData.pagination,
          "onUpdate:pagination": _cache[6] || (_cache[6] = ($event: any) => (_ctx.listData.pagination = $event)),
          onRequest: _ctx.fetchList,
          onTryAgain: _ctx.fetchList,
          paginationStyle: "v3",
          paginationWidth: "w-3/4",
          pinnedSubstractHeight: "305px"
        }, null, 8, ["loading", "columns", "data", "isEmpty", "onClick", "pagination", "onRequest", "onTryAgain"])
      ])
    ]),
    _createVNode(_component_WidgetDownload),
    _createVNode(_component_ModalDownload, {
      modelValue: _ctx.isDownload,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.isDownload = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}