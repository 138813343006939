
import { Vue, Options, prop } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ReportController } from "@/app/ui/controllers/ReportController";
import {
  formatDateNumber,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
}
@Options({
  emits: ["update:modelValue"],
  components: {
    ModalForm
  }
})
export default class ModalDownload extends Vue.with(Props) {
  onClose() {
    this.$emit("update:modelValue", false);
    this.onReset();
  }

  mounted() {
    this.onReset();
  }

  onReset() {
    this.filter = new ApiRequestList({
      startDate: "",
      endDate: ""
    });
  }

  filter = new ApiRequestList();

  // date
  setDateRange(value: any[]) {
    this.filter.startDate = value[0];
    this.filter.endDate = value[1];
  }

  get isDisabled(): boolean {
    const { startDate, endDate } = this.filter;
    return !startDate && !endDate;
  }

  async onDownload() {
    try {
      MainAppController.showLoading();
      const { startDate, endDate } = this.filter;

      await DexAssessmentController.getDownloadReport(
        new ApiRequestList({
          page: undefined,
          limit: undefined,
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate)
        })
      );

      await ReportController.onGetListDownloadReport();
      await ReportController.setOpenDownloadRequest(true);
      this.onClose();
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Gagal Download Data !", this.onDownload)
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
