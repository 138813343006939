
/* eslint-disable @typescript-eslint/camelcase */
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { Options, Vue } from "vue-class-component";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { OptionsClass } from "@/domain/entities/MainApp";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import debounce from "lodash/debounce";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import { ReasonData } from "@/domain/entities/PodDex";
import Checkbox from "primevue/checkbox";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { ConsolidatorByCityData } from "@/domain/entities/Partner";
import { UserController } from "@/app/ui/controllers/UserController";
import { RequestListUser } from "@/data/payload/api/UserApiRequest";
import { UserData } from "@/domain/entities/User";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import {
  RequestDexAssessmentL1,
  DexAssessmentList,
  DexAssessmentData
} from "@/domain/entities/DexAssessment";
import { PaginationV2 } from "@/domain/entities/Pagination";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import ModalDownload from "./components/modal-download.vue";
import formatDateBasedTimezoneValue from "@/app/infrastructures/misc/common-library/FormatDateBasedTimezoneValue";

@Options({
  components: {
    Checkbox,
    ModalDownload
  }
})
export default class List extends Vue {
  mounted() {
    this.getReasonList();
  }
  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  filter = {
    search: "",
    startDate: "",
    endDate: "",
    reason: [],
    statusDexAssesment: new OptionsClass(),
    statusDisputeConsole: new OptionsClass(),
    customerService: "" as OptionsClass | string,
    console: "" as OptionsClass | string
  };
  get sumFilter(): number {
    return (
      (this.filter.reason.length ? 1 : 0) +
      (this.filter.statusDexAssesment.value ? 1 : 0) +
      (this.filter.statusDisputeConsole.value ? 1 : 0) +
      ((this.filter.customerService as OptionsClass)?.value ? 1 : 0) +
      ((this.filter.console as OptionsClass)?.value ? 1 : 0)
    );
  }

  // search
  onSearch(value: string) {
    if (!value || value.length >= 3) {
      this.filter.search = value;
      this.listData.pagination.page = 1;
      this.fetchList();
    }
  }

  // date
  setDateRange(value: any[]) {
    this.filter.startDate = value[0];
    this.filter.endDate = value[1];

    if (value[0] && value[1]) {
      this.fetchList();
    }
  }

  //advanced filter
  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onChangeAdvancedFilter() {
    this.listData.pagination.page = 1;
    this.fetchList();
  }
  async onResetAdvancedFilter() {
    this.listData.loading = true;
    (this.$refs.dateRangeFilterListTicketDex as any)?.onSelectOption(
      "hari ini",
      false
    );

    const today = formatDateNumber(new Date());
    Object.assign(this.filter, {
      search: "",
      startDate: today,
      endDate: today,
      reason: [],
      statusDexAssesment: new OptionsClass(),
      statusDisputeConsole: new OptionsClass(),
      customerService: "",
      console: ""
    });

    this.listData.pagination.page = 1;
    await this.fetchList();
  }

  // reason
  async getReasonList() {
    await PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: "DEX",
        reasonStatus: "active"
      })
    );
  }
  get reasonData(): OptionsClass[] {
    return PodDexController.reasonList.map(
      (key: ReasonData) =>
        new OptionsClass({
          name: key.reasonDescription,
          value: key.reasonCode
        })
    );
  }
  get totalReasonValue(): string {
    if (this.filter.reason.length) {
      return `${this.filter.reason.length} Alasan DEX`;
    }
    return "";
  }

  // status DEX Assesment
  get statusDexAssesmentData(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "Sesuai",
        value: "valid"
      }),
      new OptionsClass({
        name: "Tidak Sesuai",
        value: "invalid"
      }),
      new OptionsClass({
        name: "Belum Ada Status",
        value: "-"
      })
    ];
  }
  selectStatusDexAssesment(data: OptionsClass) {
    Object.assign(this.filter, { statusDexAssesment: data });
    this.onChangeAdvancedFilter();
  }

  // status dispute console
  get statusDisputeConsoleData(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "Pengajuan",
        value: "requested"
      }),
      new OptionsClass({
        name: "Tidak Disetujui",
        value: "rejected"
      }),
      new OptionsClass({
        name: "Disetujui",
        value: "approved"
      }),
      new OptionsClass({
        name: "Belum Ada Status",
        value: "-"
      })
    ];
  }
  selectStatusDisputeConsole(data: OptionsClass) {
    Object.assign(this.filter, { statusDisputeConsole: data });
    this.onChangeAdvancedFilter();
  }

  // customer service
  isLoadingCustomerService = true;
  customerServicesData: OptionsClass[] = [];
  fetchCustomerService = debounce(async (search: string, type = "") => {
    const callApi =
      this.filter.customerService && type === "focus"
        ? !(this.filter.customerService as OptionsClass)?.value
        : true;
    if (callApi) {
      this.isLoadingCustomerService = true;
      const res = await UserController.getUserList(
        new RequestListUser({
          version: "2",
          page: 1,
          limit: 20,
          isTotalData: true,
          account_type: "customer-service",
          search: search,
          status: "active"
        })
      );
      this.customerServicesData = res.data.map(
        (item: UserData) =>
          new OptionsClass({
            name: item.username,
            value: item.username
          })
      );
      this.isLoadingCustomerService = false;
    }
  }, 250);
  onChangeCustomerService(value: OptionsClass) {
    this.filter.customerService = value;
    this.onChangeAdvancedFilter();
  }

  // console
  isLoadingConsole = true;
  get consoleData(): OptionsClass[] {
    return PartnerController.consolidatorsByCity.consolidatorByCityData.map(
      (item: ConsolidatorByCityData) =>
        new OptionsClass({
          name: item.name,
          value: item.name
        })
    );
  }
  fetchConsole = debounce(async (search: string, type = "") => {
    const callApi =
      this.filter.console && type === "focus"
        ? !(this.filter.console as OptionsClass)?.value
        : true;
    if (callApi) {
      this.isLoadingConsole = true;
      await PartnerController.getConsolidatorsByCity({
        search: search,
        page: 1,
        limit: 10,
        cityCode: "",
        type: "console",
        status: ""
      });
      this.isLoadingConsole = false;
    }
  }, 250);
  onChangeConsole(value: OptionsClass) {
    this.filter.console = value;
    this.onChangeAdvancedFilter();
  }

  listData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchList() {
    try {
      this.listData.loading = true;
      const {
        startDate,
        endDate,
        reason,
        statusDexAssesment,
        statusDisputeConsole,
        customerService,
        console
      } = this.filter;

      const res: DexAssessmentList = await DexAssessmentController.getDexAssessmentList(
        new RequestDexAssessmentL1({
          page: this.listData.pagination.page,
          limit: this.listData.pagination.limit,
          q: this.filter.search,
          startDexDate: formatDateNumber(startDate),
          endDexDate: formatDateNumber(endDate),
          reasonCode: reason.join(","),
          daStatus: statusDexAssesment.value,
          statusDisputePartner: statusDisputeConsole.value,
          csName: (customerService as OptionsClass)?.value,
          partnerName: (console as OptionsClass)?.value
        })
      );
      this.listData.pagination = new PaginationV2({
        page: res.pagination.page,
        limit: res.pagination.limit,
        totalData: res.pagination.totalData
      });
      this.listData.data = res.data;
    } catch (err) {
      this.listData.message = parsingErrorResponse(err).type;
    } finally {
      this.listData.loading = false;
    }
  }
  columnValue(params: { value: string; status?: boolean; date?: boolean }) {
    // eslint-disable-next-line prefer-const
    let { value, status, date } = params;

    if (status) {
      const title: any = {
        valid: "Sesuai",
        invalid: "Tidak Sesuai",
        approved: "Disetujui",
        requested: "Pengajuan",
        rejected: "Tidak Disetujui"
      };
      switch (value) {
        case "valid":
        case "approved":
          return ChipsStyles({ title: title[value], status: "green" });

        case "requested":
          return ChipsStyles({ title: title[value], status: "yellow" });

        case "invalid":
        case "rejected":
          return ChipsStyles({ title: title[value], status: "red" });

        default:
          return ChipsStyles({ title: "-", status: "gray" });
      }
    } else if (date) {
      value = formatDate(value);
    }
    return `<span class='text-left text-black-lp-300 flex'>${value ||
      "-"}</span>`;
  }
  columns = [
    {
      name: "Tiket ID",
      styleHead: "w-40 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daTicketNo })
    },
    {
      name: "No. STT",
      styleHead: "w-48 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daSttNo })
    },
    {
      name: "Tanggal DEX",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({
          value: formatDateBasedTimezoneValue(item.daDeliveryDexDateLocal)
        })
    },
    {
      name: "Alasan DEX",
      styleHead: "w-72 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daDeliveryReasonTitle })
    },
    {
      name: "Status DEX Asesmen",
      styleHead: "w-56 text-left text-black-lp-300",
      render: (item: DexAssessmentData) =>
        this.columnValue({
          value: item.daStatus,
          status: true
        })
    },
    {
      name: "Status Ditentukan Oleh",
      styleHead: "w-56 text-left text-black-lp-300",
      render: (item: DexAssessmentData) =>
        ChipsStyles({ title: item.daStatusAssesBy, status: "gray" })
    },
    {
      name: "Status Sanggah",
      styleHead: "w-56 text-left text-black-lp-300",
      render: (item: DexAssessmentData) =>
        this.columnValue({
          value: item.daStatusDisputePartner,
          status: true
        })
    },
    {
      name: "Nama CS L1",
      styleHead: "w-72 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daAssignedL1Name })
    },
    {
      name: "Tanggal Ditugaskan CS L1",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daAssignedL1Date, date: true })
    },
    {
      name: "Tanggal Diselesaikan CS L1",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daFinishedL1Date, date: true })
    },
    {
      name: "Nama CS L2",
      styleHead: "w-72 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daAssignedL2Name })
    },
    {
      name: "Tanggal Ditugaskan CS L2",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daAssignedL2Date, date: true })
    },
    {
      name: "Tanggal Diselesaikan CS L2",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daFinishedL2Date, date: true })
    },
    {
      name: "Nama Konsolidator",
      styleHead: "w-72 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daAssignedPartnerName })
    },
    {
      name: "Tanggal Ditugaskan Konsolidator",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({
          value: item.daAssignedPartnerDate,
          date: true
        })
    },
    {
      name: "Tanggal Diselesaikan Konsolidator",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({
          value: item.daFinishedPartnerDate,
          date: true
        })
    },
    {
      name: "Nama CS L2 Sanggah",
      styleHead: "w-72 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({ value: item.daAssignedL2DisputeName })
    },
    {
      name: "Tanggal Ditugaskan CS L2 Sanggah",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({
          value: item.daAssignedL2DisputeDate,
          date: true
        })
    },
    {
      name: "Tanggal Diselesaikan CS L2 Sanggah",
      styleHead: "w-76 text-left",
      render: (item: DexAssessmentData) =>
        this.columnValue({
          value: item.daFinishedL2DisputeDate,
          date: true
        })
    }
  ];
  get isAdvancedFilter(): boolean {
    const {
      statusDexAssesment,
      statusDisputeConsole,
      customerService,
      console
    } = this.filter;

    const isCustomerService =
      typeof customerService === "string"
        ? !customerService
        : !(customerService as OptionsClass)?.value;
    const isConsole =
      typeof console === "string"
        ? !console
        : !(console as OptionsClass)?.value;

    return (
      !statusDexAssesment.value &&
      !statusDisputeConsole.value &&
      isCustomerService &&
      isConsole
    );
  }
  get isEmpty(): boolean {
    const { search, startDate, endDate, reason } = this.filter;
    return (
      !this.listData.data.length &&
      !search &&
      !startDate &&
      !endDate &&
      !reason.length &&
      this.isAdvancedFilter
    );
  }

  onClickRow(item: DexAssessmentData) {
    const url = `${this.$route.path}/${item.daId}`.replace(/\/+/g, "/");
    this.$router.push(url);
  }

  // download
  isDownload = false;
  openDownload(value: boolean) {
    this.isDownload = value;
  }
}
