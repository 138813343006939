import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-4 search-popup py-4" }
const _hoisted_2 = { class: "flex justify-end items-center pb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_ModalForm = _resolveComponent("ModalForm")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(_component_ModalForm, {
        key: 0,
        title: "Download Laporan",
        onClose: _ctx.onClose,
        modalWidth: "w-1/2",
        isFooter: false,
        stylesContent: "px-0",
        isScroll: false
      }, {
        content: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            _createVNode(_component_DataWrapper, { label: "Tanggal DEX" }, {
              default: _withCtx(() => [
                _createVNode(_component_time_range_picker, {
                  startDate: _ctx.filter.startDate,
                  endDate: _ctx.filter.endDate,
                  onSelect: _ctx.setDateRange,
                  default: "hari ini",
                  class: "mt-2"
                }, null, 8, ["startDate", "endDate", "onSelect"])
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_LpButton, {
              onClick: _ctx.onDownload,
              customClass: "px-10 py-2",
              textColor: "white",
              title: "Cari",
              disabled: _ctx.isDisabled
            }, null, 8, ["onClick", "disabled"])
          ])
        ]),
        _: 1
      }, 8, ["onClose"]))
    : _createCommentVNode("", true)
}