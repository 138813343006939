import { AccountController } from "@/app/ui/controllers/AccountController";
import moment from "moment";
import { configDates } from "./modules/Date";

const formatDateBasedTimezoneValue = (
  date: string,
  format = "DD MMMM YYYY, HH:mm"
) => {
  if (date) {
    const locales =
      AccountController.accountData.account_type_detail.countryCode;
    const formattedDate = new Date(date);

    const timezoneValue = `+${date.split("+")[1]}`;
    const utcOffset = timezoneValue.replace(":", "");

    let newDate = moment(formattedDate)
      .utcOffset(utcOffset)
      .format(format);

    const tzName = configDates(timezoneValue)[locales.toLowerCase()];
    if (tzName) {
      newDate = `${newDate} ${tzName}`;
    }
    return newDate;
  }
  return "";
};
export default formatDateBasedTimezoneValue;
